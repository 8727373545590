// @flow

import React from "react";
import {
    AssignmentForms as _AssignmentForms,
    AssignmentLayout,
    Layout,
    withPrivateRoute,
} from "@containers";

/**
 * Assignment Preparation
 */
const AssignmentForms = (props: *) => (
    <Layout {...props}>
        <AssignmentLayout title="Vul de modules in" hideFooterDetails>
            <_AssignmentForms {...props} />
        </AssignmentLayout>
    </Layout>
);

export default withPrivateRoute(
    AssignmentForms,
    "/opdrachten/:inspectionId/inspectiepunten/:visitId/formulieren",
);
